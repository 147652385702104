<template>
  <div
    :class="{
      'offers-list-card': 1,
      'card-desktop': !mobileView,
      'card-mobile': mobileView,
    }"
  >
    <div
      @click="$emit('openListingSidepanel', listing._id)"
      class="list-card-first-sector"
    >
      <img
        v-if="listing.images"
        :src="chooseCoverImage"
        alt=""
        :class="mobileView ? 'list-card-img-mobile' : 'list-card-img-desktop'"
      />
      <div
        v-else
        :class="{
          'list-card-img-desktop': !mobileView,
          'list-card-img-mobile': mobileView,
          'empty-image': 1,
        }"
      />
      <h5 class="listing-name">
        {{ listing.listingName }}
      </h5>
      <div v-if="!mobileView" class="listing-description content-small">
        {{ listing.description }}
      </div>
    </div>

    <div class="separator" />

    <div
      v-if="activeNavItem === 'offers'"
      class="list-card-second-sector"
      :style="mobileView ? 'min-height: 96px' : null"
    >
      <div class="content-small" style="min-width: 100px">
        {{ $t("WishToView") }}
      </div>
      <div style="display: flex; gap: 8px">
        <button
          @click="$emit('reject', listing._id)"
          class="transparent-button"
          :style="mobileView ? 'width: 80px' : 'width: 122px'"
        >
          <div>
            {{ $t("No") }}
          </div>
        </button>
        <button
          @click="$emit('accept', listing._id)"
          class="transparent-button yes-button"
          :style="mobileView ? 'width: 80px' : 'width: 122px'"
        >
          <div class="content-bold">
            {{ $t("Yes") }}
          </div>
        </button>
      </div>
    </div>

    <div
      v-if="activeNavItem === 'lost'"
      class="list-card-second-sector-grid"
      :style="mobileView ? 'min-height: 96px' : null"
    >
      <div class="content-small" style="min-width: 100px">
        {{ $t("ReviewOfferAgain") }}
      </div>
      <div style="width: 32px">
        <button
          @click="$emit('undoLost', listing._id)"
          class="transparent-button yes-button"
          :style="mobileView ? 'width: 168px' : 'width: 256px'"
        >
          <img src="../../assets/images/undo.svg" alt="" />
        </button>
      </div>
    </div>

    <div v-if="haveButtonsSector" class="separator" />

    <div
      :class="{
        'list-card-third-sector': 1,
        'third-sector-desktop': !mobileView,
        'third-sector-mobile': mobileView,
      }"
    >
      <div>
        {{ $nMapper.price(listing.price, "€") }}
      </div>
      <div>
        {{ $nMapper.price(listing.pricePerSquareMeter, "€/m²") }}
      </div>
      <div>
        {{ $nMapper.price(listing.expensesInSummer, "€") }}
      </div>
      <div>
        {{
          listing.availableImmediately
            ? $t("Immediately")
            : $lfMapper.dateAvailable(listing.dateAvailable)
        }}
      </div>
      <div>
        {{ $lfMapper.floors(listing.floor, listing.floors) }}
      </div>
      <div>
        {{ listing.buildYear ? listing.buildYear : "-" }}
      </div>
      <div>
        {{ $nMapper.price(listing.generalSurfaceArea, "m²") }}
      </div>
      <div>
        {{ listing.rooms ? listing.rooms : "-" }}
      </div>
      <div>
        {{ listing.bedrooms ? listing.bedrooms : "-" }}
      </div>
      <div>
        {{ listing.bathroomCount ? listing.bathroomCount : "-" }}
      </div>
      <div>
        {{ listing.roomsSeparate ? listing.roomsSeparate : "-" }}
      </div>
      <div>
        {{ $lfMapper.condition(listing.condition) }}
      </div>
      <div>
        {{ $lfMapper.ownershipForm(listing.ownershipForm) }}
      </div>
      <div
        style="cursor: pointer; text-decoration-line: underline"
        @click="openCadastralLink(listing.cadastralNumber)"
      >
        {{
          listing.showCadastralNumber && listing.cadastralNumber
            ? listing.cadastralNumber
            : "-"
        }}
      </div>
      <div
        style="cursor: pointer; text-decoration-line: underline"
        @click="openEstateNumberLink(listing.estateNumber)"
      >
        {{
          listing.showEstateNumber && listing.estateNumber
            ? listing.estateNumber
            : "-"
        }}
      </div>
      <div>
        {{ $lfMapper.energyLabel(listing.energyLabel) }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListCard",
  components: {},
  props: ["listing", "mobileView", "activeNavItem", "haveButtonsSector"],
  data() {
    return {
      cadastralLink: "https://xgis.maaamet.ee/ky/",
      estateNumberLink: "https://ehr.ee/app/link?ehr=",
    };
  },
  created() {
  },
  mounted() {},
  computed: {
    ...mapGetters(["customerOffers"]),

    chooseCoverImage: function () {
      let coverImage = [];

      if (this.listing.isWatermarkApplied && this.listing.images[0]?.watermarkName) {
        this.listing.images.forEach((image) => {
          if (!image.hidden && image.watermarkName) {
            coverImage.push(image.watermarkName);
          }
        });
      } else {
        this.listing.images.forEach((image) => {
          if (!image.hidden && image.name) {
            coverImage.push(image.name);
          }
        });
      }
      return coverImage[0];
    },
  },
  watch: {},
  methods: {
    ...mapActions(["setCustomerOffers"]),
    openCadastralLink(cadastralLink) {
      window.open(this.cadastralLink + cadastralLink, "_blank");
    },
    openEstateNumberLink(estateNumberLink) {
      window.open(this.estateNumberLink + estateNumberLink, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-list-card {
  background: white;
  padding: 12px;
}

.card-desktop {
  width: 280px;
}

.card-mobile {
  width: 192px;
}

.list-card-img-desktop {
  width: 256px;
  height: 160px;
  border-radius: 8px;
}

.list-card-img-mobile {
  width: 168px;
  height: 105px;
  border-radius: 8px;
}

.list-card-first-sector {
  cursor: pointer;
}

.list-card-second-sector {
  min-height: 78px;
  display: grid;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
}

.list-card-second-sector-grid {
  height: 78px;
  display: grid;
  gap: 8px;
  padding: 12px 0;
  align-items: center;
}

.list-card-third-sector {
  display: grid;
  gap: 16px;
  padding-top: 12px;

  div {
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.third-sector-desktop {
}

.third-sector-mobile {
  margin-top: 32px;
  gap: 26px;
}

.transparent-button {
  padding: 4px 12px;
  box-shadow: none;
  border: 1px solid $grey500;
  justify-content: center;
}

.yes-button {
  color: white;
  background: $green !important;
  border: none;
}

.listing-name {
  height: 24px;
  margin-top: 8px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
}

.listing-description {
  margin-bottom: 8px;
  height: 34px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.empty-image {
  background: $grey500;
  margin-bottom: 13px;
}

.separator {
  height: 1px;
  width: 100%;
  background: $grey500;
}
</style>
