<template>
  <div
      :class="{
       'offers-listings-fields-labels': 1,
       'fields-desktop-wrapper': !mobileView,
       'fields-mobile-wrapper': mobileView,
      }"
      :style="haveButtonsSector ? null : 'top: 243px'"
  >
    <div
        v-if="!mobileView"
        class="field-labels-desktop"
    >
      <div
        v-for="(field, index) in fields"
        :key="'field' + index"
        class="field-label-desktop content-medium"
      >
        {{ $t(field) }}
      </div>

    </div>
    <div
        v-else
        class="field-labels-mobile"
    >
      <div
          v-for="(field, index) in fields"
          :key="'field' + index"
          class="field-label-mobile content-small"
      >
        {{ $t(field) }}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "ListingsFieldsLabels",
  components: {
  },
  props: ["activeView", "mobileView", "haveButtonsSector"],
  data() {
    return {
      fields: [
          'Price',
          'PricePerSquareMeter',
          'ExpensesInSummer',
          'AvailableImmediately',
          'Floors',
          'BuildYear',
          'GeneralSurfaceArea',
          'Rooms',
          'Bedrooms',
          'BathroomCount',
          'RoomsSeparate',
          'Condition',
          'OwnershipForm',
          'CadastralNumber',
          'EstateNumber',
          'EnergyLabel'
      ]
    };
  },
  created() {
  },
  mounted() {},
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-listings-fields-labels {
}

.field-labels-desktop {
  display: grid;
  gap: 16px;
  text-align-last: right;
  padding: 12px 24px 12px 12px;
  background: $light-grey;
  border: 1px solid $grey500;
}

.field-labels-mobile {
  display: grid;
  gap: 30px;
}

.field-label-desktop {
  color: $grey950;
}

.field-label-mobile {
  background: $light-yellow;
  padding: 0px 4px 0px 32px;
  border-radius: 0 4px 4px 0;
  width: fit-content;
  font-size: 11px;
  font-weight: 600;
}

.fields-desktop-wrapper {

}

.fields-mobile-wrapper {
  position: absolute;
  top: 340px;
}
</style>
