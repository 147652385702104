var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    class: {
      'offers-top-nav-bar': 1,
      'offers-top-nav-bar-desktop': !_vm.mobileView,
      'offers-top-nav-bar-mobile': _vm.mobileView
    }
  }, [_c('div', {
    class: {
      'offers-top-nav-bar-item': 1,
      'active-view': _vm.activeView === 'list',
      'active-view-mobile': _vm.activeView === 'list' && _vm.mobileView
    },
    staticStyle: {
      "display": "none"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveView', 'list');
      }
    }
  }, [_c('img', {
    staticClass: "img20",
    attrs: {
      "src": require("../../assets/images/bulletedList.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("List")) + " ")])]), _c('div', {
    class: {
      'offers-top-nav-bar-item': 1,
      'active-view': _vm.activeView === 'map',
      'active-view-mobile': _vm.activeView === 'map' && _vm.mobileView
    },
    staticStyle: {
      "display": "none"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveView', 'map');
      }
    }
  }, [_c('img', {
    staticClass: "img20",
    attrs: {
      "src": require("../../assets/images/map.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("MapView")) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }