<template>
  <div class="offers-list-content">

    <div
        v-if="mobileView"
        style="min-width: 20px"
    />

    <div
        :class="{
          'offers-list-cards': 1,
          'list-cards-mobile': mobileView
        }"
    >
      <ListCard
          v-for="(listing, index) in customerOffers[activeNavItem]"
          :key="'mapCard' + listing._id"
          :listing="listing"
          :mobileView="mobileView"
          :activeNavItem="activeNavItem"
          :class="{
            'mobile-card-first': mobileView && index === 0,
            'mobile-card-last': mobileView && index === customerOffers[activeNavItem].length - 1,
          }"
          :haveButtonsSector="haveButtonsSector"
          @openListingSidepanel="(listingId) => $emit('openListingSidepanel', listingId)"
          @reject="(listingId) => $emit('reject', listingId)"
          @accept="(listingId) => $emit('accept', listingId)"
          @undoLost="(listingId) => $emit('undoLost', listingId)"
      />
    </div>


  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import ListCard from "@/components/Offers/ListCard";

export default {
  name: "OffersListContent",
  components: {
    ListCard
  },
  props: ["mobileView", "activeNavItem", "haveButtonsSector"],
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["customerOffers"]),
  },
  watch: {
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-list-content {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.offers-list-cards {
  background: $grey500;
  display: flex;
  border: 1px solid $grey500;
  gap: 2px;
}

.list-cards-mobile {
  border-radius: 8px;
}

.mobile-card-first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mobile-card-last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

</style>
