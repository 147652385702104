var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "offers-list-content"
  }, [_vm.mobileView ? _c('div', {
    staticStyle: {
      "min-width": "20px"
    }
  }) : _vm._e(), _c('div', {
    class: {
      'offers-list-cards': 1,
      'list-cards-mobile': _vm.mobileView
    }
  }, _vm._l(_vm.customerOffers[_vm.activeNavItem], function (listing, index) {
    return _c('ListCard', {
      key: 'mapCard' + listing._id,
      class: {
        'mobile-card-first': _vm.mobileView && index === 0,
        'mobile-card-last': _vm.mobileView && index === _vm.customerOffers[_vm.activeNavItem].length - 1
      },
      attrs: {
        "listing": listing,
        "mobileView": _vm.mobileView,
        "activeNavItem": _vm.activeNavItem,
        "haveButtonsSector": _vm.haveButtonsSector
      },
      on: {
        "openListingSidepanel": function (listingId) {
          return _vm.$emit('openListingSidepanel', listingId);
        },
        "reject": function (listingId) {
          return _vm.$emit('reject', listingId);
        },
        "accept": function (listingId) {
          return _vm.$emit('accept', listingId);
        },
        "undoLost": function (listingId) {
          return _vm.$emit('undoLost', listingId);
        }
      }
    });
  }), 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }