<template>
  <div>
    <div
        class="offers-header"
        :style="mobileView ? 'padding-bottom: 0' :'padding-bottom: 42px'"
    >
      <div
          class="offers-header-first-row"
      >
        <div
            class="offers-header-first-col"
            :style="mobileView ? 'display: grid' : 'display: flex'"
        >
          <div class="offers-header-logo"
              :style="mobileView ? 'padding-left: 8px' : 'padding-left: 0px'"
          >
            <img style="height: 100%;" v-if="company"
                 :src="company.name"
                 alt=""
            />
            <img v-else
                src="../../assets/images/rpFullLogo.svg"
                alt=""
            />
          </div>

          <div class="offers-header-text">
            <h4
                v-if="customer"
            >
              {{ $t("Hello") }}
              {{ customer.customerName }}
            </h4>
            <div>
              {{ $t("OffersInfo") }}
            </div>
          </div>

        </div>
        <div>
          <LanguageDropdown
          />
        </div>
      </div>
      <TopNavBar
          v-if="mobileView"
          :mobileView="mobileView"
          :activeView="activeView"
          @changeActiveView="(view) => $emit('changeActiveView', view)"
      />
    </div>
    <div class="separator"></div>
    <TopNavBar
        v-if="!mobileView"
        :mobileView="mobileView"
        :activeView="activeView"
        @changeActiveView="(view) => $emit('changeActiveView', view)"
    />
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import TopNavBar from "@/components/Offers/TopNavBar";
import LanguageDropdown from "@/components/common/LanguageDropdown";
export default {
  name: "OffersHeader",
  props: ["customer", "mobileView", "activeView", "company"],
  components: {
    TopNavBar,
    LanguageDropdown
  },
  data() {
    return {
    };
  },
  created() {   
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-header-first-col {
  display: flex;
  width: inherit;
  align-items: center;
  gap: 16px;
}

.offers-header-first-row {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: inherit;
}

.offers-header {
  width: 100%;
  padding: 42px;
  background: #FFFFFF;
  gap: 24px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.separator {
  border-top: 1px solid #000000;
  width: 100%;
  height: 1px;
}

.offers-header-logo {
  min-width: 248px;
  height: 56px;
}

.offers-header-text {
  min-width: 150px;
  max-width: 680px;
  padding-left: 16px;
  display: grid;
  gap: 8px;
}
</style>
