<template>
  <div class="widget-overlay">
    <div
        class="offers-nav-bar-panel"
        v-click-outside="() => extended = false"
    >

      <div
        v-show="extended"
        class="nav-bar-buttons"
      >
        <div
            :class="{
              'transparent-button': 1,
              'active-button': activeNavItem === 'offers'
            }"
            @click="$emit('changeActiveNavItem', 'offers')"
        >
          <div>
            {{ $t("Offers") }}
          </div>
          <div>
            ({{ customerOffers.offers.length }})
          </div>
        </div>

        <div
            :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'interested'
        }"
            @click="$emit('changeActiveNavItem', 'interested')"
        >
          <div>
            {{ $t("Selected") }}
          </div>
          <div>
            ({{ customerOffers.interested.length }})
          </div>
        </div>

        <div
            :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'lost'
        }"
            @click="$emit('changeActiveNavItem', 'lost')"
        >
          <div>
            {{ $t("Rejected") }}
          </div>
          <div>
            ({{ customerOffers.lost.length }})
          </div>
        </div>

        <div
            :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'viewed'
        }"
            @click="$emit('changeActiveNavItem', 'viewed')"
        >
          <div>
            {{ $t("Viewed") }}
          </div>
          <div>
            ({{ customerOffers.viewed.length }})
          </div>
        </div>
      </div>

      <div class="panel-icon-row">
        <div
            class="panel-icon-con"
            @click="extended = !extended"
        >
          <img
              :src="
            extended
              ? require('../../assets/images/close.svg')
              : require('../../assets/images/hamburger-menu.svg')
            "
              alt=""
              class="panel-icon"
          />
        </div>

      </div>

    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: "OffersNavBarPanel",
  components: {
  },
  props: ["activeNavItem"],
  data() {
    return {
      extended: false,
      customerOffersCopy: null,
    };
  },
  created() {
    this.customerOffersCopy = this.customerOffers;
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["customerOffers"]),
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.widget-overlay {
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: end;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
  padding-right: 40px;
}

.offers-nav-bar-panel {
  background: $green300;
  border-radius: 8px;
}

.panel-items {
  padding: 16px 20px;
}

.panel-icon-con {
  cursor: pointer;
  padding: 16px 20px;
}

.panel-icon {
  width: 24px;
  height: 24px;
}

.panel-icon-row {
  float: right;
  height: 56px;
}

.transparent-button {
  min-width: 199px;
  justify-content: center;
  background: white !important;
  box-shadow: none;
  padding: 16px;
}

.active-button {
  background: black !important;
  color: white;
}

.nav-bar-buttons {
  padding: 24px 24px 0 24px;
  display: grid;
  gap: 10px;
}

</style>
