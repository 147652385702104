<template>
  <div class="offers-map-content">

<!--    <MapCard-->
<!--      v-for="listing in customerOffersCopy[activeNavItem]"-->
<!--      :key="'mapCard' + listing._id"-->
<!--      :listing="listing"-->
<!--      :mobileView="mobileView"-->
<!--      :activeNavItem="activeNavItem"-->
<!--      @openListingSidepanel="(listingId) => $emit('openListingSidepanel', listingId)"-->
<!--      @reject="(listingId) => $emit('reject', listingId)"-->
<!--      @accept="(listingId) => $emit('accept', listingId)"-->
<!--    />-->

  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
// import MapCard from "@/components/Offers/MapCard";

export default {
  name: "OffersMapContent",
  components: {
    // MapCard
  },
  props: ["mobileView", "activeNavItem"],
  data() {
    return {
      customerOffersCopy: null,
    };
  },
  created() {
    this.customerOffersCopy = this.customerOffers;
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["customerOffers"]),
  },
  watch: {
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-map-content {
  height: calc(100vh - 530px)
}
</style>
