var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "offers-content",
    style: _vm.mobileView ? 'max-width: 100%' : 'max-width: calc(100% - 310px)'
  }, [_vm.mobileView ? _c('div', {
    staticClass: "offers-third-header"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.activeNavItemTranslate) + " (" + _vm._s(_vm.customerOffers[_vm.activeNavItem].length) + ") ")])]) : _vm._e(), _vm.customerOffers[_vm.activeNavItem].length > 0 ? _c('div', [_vm.activeView === 'list' ? _c('ListContent', {
    key: _vm.dataUpdater,
    attrs: {
      "mobileView": _vm.mobileView,
      "activeNavItem": _vm.activeNavItem,
      "haveButtonsSector": _vm.haveButtonsSector
    },
    on: {
      "openListingSidepanel": _vm.openListingSidepanel,
      "reject": _vm.reject,
      "accept": _vm.accept,
      "undoLost": _vm.undoLost
    }
  }) : _vm._e(), _vm.activeView === 'map' ? _c('MapContent', {
    key: _vm.dataUpdater,
    attrs: {
      "mobileView": _vm.mobileView,
      "activeNavItem": _vm.activeNavItem
    },
    on: {
      "openListingSidepanel": _vm.openListingSidepanel,
      "reject": _vm.reject,
      "accept": _vm.accept,
      "undoLost": _vm.undoLost
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "empty-content",
    staticStyle: {
      "height": "calc(100vh - 530px)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoListingsToShow")) + " ")]), _vm.mobileView && _vm.customerOffers[_vm.activeNavItem].length > 0 && _vm.activeView === 'list' ? _c('ListingsFieldsLabels', {
    attrs: {
      "mobileView": _vm.mobileView,
      "haveButtonsSector": _vm.haveButtonsSector,
      "activeNavItem": _vm.activeNavItem
    }
  }) : _vm._e(), _vm.mobileView ? _c('NavBarPanel', {
    attrs: {
      "mobileView": _vm.mobileView,
      "activeNavItem": _vm.activeNavItem
    },
    on: {
      "changeActiveNavItem": function (navItem) {
        return _vm.$emit('changeActiveNavItem', navItem);
      }
    }
  }) : _vm._e(), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.chosenListingId,
      "public-listing": true,
      "mobileView": _vm.mobileView,
      "activeNavItem": _vm.activeNavItem,
      "hidden-marketplaces": true
    },
    on: {
      "overlayClicked": _vm.closeListingPanel,
      "reject": function (item) {
        _vm.closeListingPanel();
        _vm.reject(item);
      },
      "accept": function (item) {
        _vm.closeListingPanel();
        _vm.accept(item);
      },
      "undoLost": function (item) {
        _vm.closeListingPanel();
        _vm.undoLost(item);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }