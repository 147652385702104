var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.mobileView ? _c('div', {
    class: {
      'offers-sidebar': 1,
      'offers-sidebar-desktop': !_vm.mobileView
    }
  }, [!_vm.mobileView ? _c('SideNavBar', {
    attrs: {
      "activeNavItem": _vm.activeNavItem,
      "haveButtonsSector": _vm.haveButtonsSector
    },
    on: {
      "changeActiveNavItem": function (navItem) {
        return _vm.$emit('changeActiveNavItem', navItem);
      }
    }
  }) : _vm._e(), !_vm.mobileView && _vm.customerOffers[_vm.activeNavItem].length !== 0 ? _c('ListingsFieldsLabels', {
    attrs: {
      "mobileView": _vm.mobileView
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }