var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'offers-listings-fields-labels': 1,
      'fields-desktop-wrapper': !_vm.mobileView,
      'fields-mobile-wrapper': _vm.mobileView
    },
    style: _vm.haveButtonsSector ? null : 'top: 243px'
  }, [!_vm.mobileView ? _c('div', {
    staticClass: "field-labels-desktop"
  }, _vm._l(_vm.fields, function (field, index) {
    return _c('div', {
      key: 'field' + index,
      staticClass: "field-label-desktop content-medium"
    }, [_vm._v(" " + _vm._s(_vm.$t(field)) + " ")]);
  }), 0) : _c('div', {
    staticClass: "field-labels-mobile"
  }, _vm._l(_vm.fields, function (field, index) {
    return _c('div', {
      key: 'field' + index,
      staticClass: "field-label-mobile content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t(field)) + " ")]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }