<template>
  <div v-if="customerOffers" class="content">
    <div>
      <Header
        id="offers-header"
        :customer="customer"
        :company="company"
        :mobileView="mobileView"
        :activeView="activeView"
        @changeActiveView="(view) => (activeView = view)"
      />
      <div id="offers-body" class="offers-body">
        <Sidebar
          :activeView="activeView"
          :mobileView="mobileView"
          :activeNavItem="activeNavItem"
          :haveButtonsSector="haveButtonsSector"
          @changeActiveNavItem="changeActiveNavItem"
        />

        <Content
          :activeView="activeView"
          :mobileView="mobileView"
          :activeNavItem="activeNavItem"
          :customer="customer"
          :haveButtonsSector="haveButtonsSector"
          @changeActiveNavItem="changeActiveNavItem"
        />
      </div>
    </div>

    <div class="offers-footer-spacer" style="height: 50px" />

    <Footer
      id="offers-footer"
      :mobileView="mobileView"
      :activeView="activeView"
      :broker="broker"
    />
  </div>
</template>

<script>
import Header from "@/components/Offers/Header.vue";
import contactApi from "@/http/contact";
import listingApi from "@/http/listing";
import Content from "@/components/Offers/Content.vue";
import { mapActions, mapGetters } from "vuex";
import Footer from "@/components/Offers/Footer";
import Sidebar from "@/components/Offers/Sidebar";
import axios from "axios";
export default {
  name: "Offers",
  components: {
    Header,
    Content,
    Footer,
    Sidebar,
  },
  data() {
    return {
      activeView: "list",
      activeNavItem: "offers",
      customer: null,
      mobileView: false,
      haveButtonsSector: false,
      defaultLanguage: "et",
      broker: null,
      company: null,
    };
  },
  async created() {
    this.mobileView = window.innerWidth < 1000;
    window.addEventListener(
      "resize",
      () => (this.mobileView = window.innerWidth < 1000)
    );

    this.changeButtonsSectorStatus();

    await this.initData();
  },
  mounted() {},
  watch: {
    activeNavItem() {
      this.changeButtonsSectorStatus();
    },
  },
  computed: {
    ...mapGetters(["customerOffers", "languageSet", "users"]),
  },
  methods: {
    ...mapActions(["setCustomerOffers", "setLanguage"]),
    changeActiveNavItem(navItem) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.activeNavItem = navItem;
    },
    async initData() {
      await this.initCustomer();
      await this.initListings();
      await this.initBroker(this.customer);
    },
    async initBroker() {
      let brokerId = [];
      if (this.customer.creator) {
        brokerId.push(this.customer.creator.userId);
      }
      let response = await axios.post("/api/users-preview", brokerId);
      this.broker = response.data;
    },
    async initCustomer() {
      const customerId = this.$route.params.id;

      const contactFields = {
        _id: 1,
        customerId: 1,
        customerName: 1,
        offersUrl: 1,
        creator: 1,
      };

      const response = await contactApi.getContactPublicCustomById(customerId, {
        contactFields,
      });

      if (response.status !== 200) await this.$router.push("/");

      this.customer = response.data;

      await axios
        .get(`/api/company/public/${this.customer.creator.companyId}`)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data[0] &&
            response.data[0].companyLogo
          ) {
            this.company = response.data[0].companyLogo;
          }
        });
    },
    async initListings() {
      const response = await listingApi.getListingsPublicCustomCustomerOffers(
        this.customer.customerId
      );

      if (response.status !== 200) return;

      let customerOffers = this.getEmptyCustomerOffers();

      let listings = response.data.listings;
      let projectIds = response.data.projectIds;
      listings.forEach((listing) => {
        if (
          listing.funnels.Offers.some(
            (offer) =>
              offer.leadId === this.customer.customerId ||
              projectIds.includes(offer.leadId)
          )
        ) {
          customerOffers.offers.push(listing);
        } else if (
          listing.funnels.Interested.some(
            (offer) =>
              offer.leadId === this.customer.customerId ||
              projectIds.includes(offer.leadId)
          )
        ) {
          customerOffers.interested.push(listing);
        } else if (
          listing.funnels["Lost / Archived"].some(
            (offer) =>
              offer.leadId === this.customer.customerId ||
              projectIds.includes(offer.leadId)
          )
        ) {
          customerOffers.lost.push(listing);
        } else if (
          listing.funnels.Viewed.some(
            (offer) =>
              offer.leadId === this.customer.customerId ||
              projectIds.includes(offer.leadId)
          )
        ) {
          customerOffers.viewed.push(listing);
        } else if (
          listing.funnels["Negotiation & Contract"].some(
            (offer) =>
              offer.leadId === this.customer.customerId ||
              projectIds.includes(offer.leadId)
          )
        ) {
          customerOffers.viewed.push(listing);
        }
      });
      this.setCustomerOffers(customerOffers);
    },
    changeButtonsSectorStatus() {
      this.haveButtonsSector =
        this.activeNavItem === "offers" || this.activeNavItem === "lost";
    },
    getEmptyCustomerOffers() {
      return {
        offers: [],
        interested: [],
        lost: [],
        viewed: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.content {
  background: white;
}

.offers-body {
  display: flex;
}
</style>
