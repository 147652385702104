var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'offers-list-card': 1,
      'card-desktop': !_vm.mobileView,
      'card-mobile': _vm.mobileView
    }
  }, [_c('div', {
    staticClass: "list-card-first-sector",
    on: {
      "click": function ($event) {
        return _vm.$emit('openListingSidepanel', _vm.listing._id);
      }
    }
  }, [_vm.listing.images ? _c('img', {
    class: _vm.mobileView ? 'list-card-img-mobile' : 'list-card-img-desktop',
    attrs: {
      "src": _vm.chooseCoverImage,
      "alt": ""
    }
  }) : _c('div', {
    class: {
      'list-card-img-desktop': !_vm.mobileView,
      'list-card-img-mobile': _vm.mobileView,
      'empty-image': 1
    }
  }), _c('h5', {
    staticClass: "listing-name"
  }, [_vm._v(" " + _vm._s(_vm.listing.listingName) + " ")]), !_vm.mobileView ? _c('div', {
    staticClass: "listing-description content-small"
  }, [_vm._v(" " + _vm._s(_vm.listing.description) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "separator"
  }), _vm.activeNavItem === 'offers' ? _c('div', {
    staticClass: "list-card-second-sector",
    style: _vm.mobileView ? 'min-height: 96px' : null
  }, [_c('div', {
    staticClass: "content-small",
    staticStyle: {
      "min-width": "100px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("WishToView")) + " ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "transparent-button",
    style: _vm.mobileView ? 'width: 80px' : 'width: 122px',
    on: {
      "click": function ($event) {
        return _vm.$emit('reject', _vm.listing._id);
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("No")) + " ")])]), _c('button', {
    staticClass: "transparent-button yes-button",
    style: _vm.mobileView ? 'width: 80px' : 'width: 122px',
    on: {
      "click": function ($event) {
        return _vm.$emit('accept', _vm.listing._id);
      }
    }
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")])])])]) : _vm._e(), _vm.activeNavItem === 'lost' ? _c('div', {
    staticClass: "list-card-second-sector-grid",
    style: _vm.mobileView ? 'min-height: 96px' : null
  }, [_c('div', {
    staticClass: "content-small",
    staticStyle: {
      "min-width": "100px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ReviewOfferAgain")) + " ")]), _c('div', {
    staticStyle: {
      "width": "32px"
    }
  }, [_c('button', {
    staticClass: "transparent-button yes-button",
    style: _vm.mobileView ? 'width: 168px' : 'width: 256px',
    on: {
      "click": function ($event) {
        return _vm.$emit('undoLost', _vm.listing._id);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/undo.svg"),
      "alt": ""
    }
  })])])]) : _vm._e(), _vm.haveButtonsSector ? _c('div', {
    staticClass: "separator"
  }) : _vm._e(), _c('div', {
    class: {
      'list-card-third-sector': 1,
      'third-sector-desktop': !_vm.mobileView,
      'third-sector-mobile': _vm.mobileView
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.listing.price, "€")) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.listing.pricePerSquareMeter, "€/m²")) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.listing.expensesInSummer, "€")) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.listing.availableImmediately ? _vm.$t("Immediately") : _vm.$lfMapper.dateAvailable(_vm.listing.dateAvailable)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$lfMapper.floors(_vm.listing.floor, _vm.listing.floors)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.listing.buildYear ? _vm.listing.buildYear : "-") + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.listing.generalSurfaceArea, "m²")) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.listing.rooms ? _vm.listing.rooms : "-") + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.listing.bedrooms ? _vm.listing.bedrooms : "-") + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.listing.bathroomCount ? _vm.listing.bathroomCount : "-") + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.listing.roomsSeparate ? _vm.listing.roomsSeparate : "-") + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$lfMapper.condition(_vm.listing.condition)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$lfMapper.ownershipForm(_vm.listing.ownershipForm)) + " ")]), _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openCadastralLink(_vm.listing.cadastralNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.showCadastralNumber && _vm.listing.cadastralNumber ? _vm.listing.cadastralNumber : "-") + " ")]), _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openEstateNumberLink(_vm.listing.estateNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.showEstateNumber && _vm.listing.estateNumber ? _vm.listing.estateNumber : "-") + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$lfMapper.energyLabel(_vm.listing.energyLabel)) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }