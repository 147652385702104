var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "offers-side-nav-bar",
    style: _vm.haveButtonsSector ? null : 'min-height: 324px'
  }, [_c('div', {
    staticClass: "offers-side-nav-bar-buttons"
  }, [_c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'offers'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'offers');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Offers")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.offers.length) + ") ")])]), _c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'interested'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'interested');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Selected")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.interested.length) + ") ")])]), _c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'lost'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'lost');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Rejected")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.lost.length) + ") ")])]), _c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'viewed'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'viewed');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Viewed")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.viewed.length) + ") ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }