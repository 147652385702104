var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.customerOffers ? _c('div', {
    staticClass: "content"
  }, [_c('div', [_c('Header', {
    attrs: {
      "id": "offers-header",
      "customer": _vm.customer,
      "company": _vm.company,
      "mobileView": _vm.mobileView,
      "activeView": _vm.activeView
    },
    on: {
      "changeActiveView": function (view) {
        return _vm.activeView = view;
      }
    }
  }), _c('div', {
    staticClass: "offers-body",
    attrs: {
      "id": "offers-body"
    }
  }, [_c('Sidebar', {
    attrs: {
      "activeView": _vm.activeView,
      "mobileView": _vm.mobileView,
      "activeNavItem": _vm.activeNavItem,
      "haveButtonsSector": _vm.haveButtonsSector
    },
    on: {
      "changeActiveNavItem": _vm.changeActiveNavItem
    }
  }), _c('Content', {
    attrs: {
      "activeView": _vm.activeView,
      "mobileView": _vm.mobileView,
      "activeNavItem": _vm.activeNavItem,
      "customer": _vm.customer,
      "haveButtonsSector": _vm.haveButtonsSector
    },
    on: {
      "changeActiveNavItem": _vm.changeActiveNavItem
    }
  })], 1)], 1), _c('div', {
    staticClass: "offers-footer-spacer",
    staticStyle: {
      "height": "50px"
    }
  }), _c('Footer', {
    attrs: {
      "id": "offers-footer",
      "mobileView": _vm.mobileView,
      "activeView": _vm.activeView,
      "broker": _vm.broker
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }