<template>
  <div
    class="offers-content"
    :style="mobileView ? 'max-width: 100%' : 'max-width: calc(100% - 310px)'"
  >
    <div v-if="mobileView" class="offers-third-header">
      <h5>
        {{ activeNavItemTranslate }} ({{
          customerOffers[activeNavItem].length
        }})
      </h5>
    </div>

    <div v-if="customerOffers[activeNavItem].length > 0">
      <ListContent
        v-if="activeView === 'list'"
        :mobileView="mobileView"
        :activeNavItem="activeNavItem"
        :haveButtonsSector="haveButtonsSector"
        @openListingSidepanel="openListingSidepanel"
        @reject="reject"
        @accept="accept"
        @undoLost="undoLost"
        :key="dataUpdater"
      />
      <MapContent
        v-if="activeView === 'map'"
        :mobileView="mobileView"
        :activeNavItem="activeNavItem"
        @openListingSidepanel="openListingSidepanel"
        @reject="reject"
        @accept="accept"
        @undoLost="undoLost"
        :key="dataUpdater"
      />
    </div>
    <div v-else class="empty-content" style="height: calc(100vh - 530px)">
      {{ $t("NoListingsToShow") }}
    </div>

    <ListingsFieldsLabels
      v-if="
        mobileView &&
        customerOffers[activeNavItem].length > 0 &&
        activeView === 'list'
      "
      :mobileView="mobileView"
      :haveButtonsSector="haveButtonsSector"
      :activeNavItem="activeNavItem"
    />

    <NavBarPanel
      v-if="mobileView"
      :mobileView="mobileView"
      :activeNavItem="activeNavItem"
      @changeActiveNavItem="(navItem) => $emit('changeActiveNavItem', navItem)"
    />

    <ListingSidepanel
      @overlayClicked="closeListingPanel"
      v-if="listingPanelOpen"
      :listing-id="chosenListingId"
      :public-listing="true"
      :mobileView="mobileView"
      :activeNavItem="activeNavItem"
      :hidden-marketplaces="true"
      @reject="
        (item) => {
          closeListingPanel();
          reject(item);
        }
      "
      @accept="
        (item) => {
          closeListingPanel();
          accept(item);
        }
      "
      @undoLost="
        (item) => {
          closeListingPanel();
          undoLost(item);
        }
      "
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ListContent from "@/components/Offers/ListContent";
import MapContent from "@/components/Offers/MapContent";
import NavBarPanel from "@/components/Offers/NavBarPanel";
import ListingsFieldsLabels from "@/components/Offers/ListingsFieldsLabels";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import listingApi from "@/http/listing";
import contactApi from "@/http/contact";

export default {
  name: "OffersContent",
  components: {
    ListContent,
    MapContent,
    NavBarPanel,
    ListingsFieldsLabels,
    ListingSidepanel,
  },
  props: [
    "activeView",
    "mobileView",
    "activeNavItem",
    "customer",
    "haveButtonsSector",
  ],
  data() {
    return {
      listingPanelOpen: false,
      chosenListingId: null,
      dataUpdater: 0,
    };
  },
  computed: {
    ...mapGetters(["customerOffers"]),
    activeNavItemTranslate: function () {
      switch (this.activeNavItem) {
        case "offers":
          return this.$t("Offers");
        case "interested":
          return this.$t("Selected");
        case "lost":
          return this.$t("Rejected");
        case "viewed":
          return this.$t("Viewed");
      }
      return "error";
    },
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    ...mapActions(["setCustomerOffers"]),
    closeListingPanel() {
      this.listingPanelOpen = false;
    },
    openListingSidepanel(listingId) {
      this.chosenListingId = listingId;
      this.listingPanelOpen = true;
    },
    async reject(listingId) {
      const res = await this.moveLeadAround(
        listingId,
        "Offers",
        "Lost / Archived",
        "offers",
        "lost"
      );
      if (res) {
        this.toastSuccess(this.$t("Done"));
      } else {
        this.toastError("Error");
      }
    },
    async accept(listingId) {
      const res = await this.moveLeadAround(
        listingId,
        "Offers",
        "Interested",
        "offers",
        "interested"
      );
      if (res) {
        this.toastSuccess(this.$t("Done"));
      } else {
        this.toastError("Error");
      }
    },
    async undoLost(listingId) {
      const res = await this.moveLeadAround(
        listingId,
        "Lost / Archived",
        "Offers",
        "lost",
        "offers"
      );
      if (res) {
        this.toastSuccess(this.$t("Done"));
      } else {
        this.toastError("Error");
      }
    },
    async moveLeadAround(
      listingId,
      fromFunnel,
      toFunnel,
      customerOfferFromFunnel,
      customerOfferToFunnel
    ) {
      let customerOffersCopy = this.customerOffers;

      const listing = this.customerOffers[customerOfferFromFunnel].find(
        (offer) => offer._id === listingId
      );

      const offer = listing.funnels[fromFunnel].find(
        (offer) => offer.leadId === this.customer.customerId
      );
      offer.public = true;

      const ListingReqBody = {
        fromFunnel: fromFunnel,
        toFunnel: toFunnel,
        offer,
      };
      const response =
        await listingApi.updateListingPublicFunnelsMoveLeadAround(
          listing._id,
          ListingReqBody
        );

      if (response.status !== 200) return false;

      listing.funnels[fromFunnel] = listing.funnels[fromFunnel].filter(
        (offer) => offer.leadId !== this.customer.customerId
      );
      listing.funnels[toFunnel].push(offer);

      customerOffersCopy[customerOfferFromFunnel] = customerOffersCopy[
        customerOfferFromFunnel
      ].filter((listing) => listing._id !== listingId);
      customerOffersCopy[customerOfferToFunnel] = [
        listing,
        ...customerOffersCopy[customerOfferToFunnel],
      ];

      this.setCustomerOffers(customerOffersCopy);
      this.dataUpdater++;

      const CustomerReqBody = {
        leadId: this.customer.customerId,
        step: toFunnel,
        listingId: listing._id,
        listingName: listing.listingName,
        previousStep: fromFunnel,
      };

      contactApi.updateCustomerPublicLead(listing._id, CustomerReqBody);
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-content {
  position: relative;
}

.offers-third-header {
  padding: 16px 32px;
}

.empty-content {
  padding: 64px;
  height: 200px;
  font-size: 18px;
  line-height: 24px;
  color: $grey950;
}
</style>
