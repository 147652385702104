var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-overlay"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.extended = false;
      },
      expression: "() => extended = false"
    }],
    staticClass: "offers-nav-bar-panel"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.extended,
      expression: "extended"
    }],
    staticClass: "nav-bar-buttons"
  }, [_c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'offers'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'offers');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Offers")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.offers.length) + ") ")])]), _c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'interested'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'interested');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Selected")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.interested.length) + ") ")])]), _c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'lost'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'lost');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Rejected")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.lost.length) + ") ")])]), _c('div', {
    class: {
      'transparent-button': 1,
      'active-button': _vm.activeNavItem === 'viewed'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeActiveNavItem', 'viewed');
      }
    }
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Viewed")) + " ")]), _c('div', [_vm._v(" (" + _vm._s(_vm.customerOffers.viewed.length) + ") ")])])]), _c('div', {
    staticClass: "panel-icon-row"
  }, [_c('div', {
    staticClass: "panel-icon-con",
    on: {
      "click": function ($event) {
        _vm.extended = !_vm.extended;
      }
    }
  }, [_c('img', {
    staticClass: "panel-icon",
    attrs: {
      "src": _vm.extended ? require('../../assets/images/close.svg') : require('../../assets/images/hamburger-menu.svg'),
      "alt": ""
    }
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }