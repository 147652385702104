<template>
  <div
    v-if="!mobileView"
    :class="{
      'offers-sidebar': 1,
      'offers-sidebar-desktop': !mobileView,
    }"
  >
    <SideNavBar
        v-if="!mobileView"
        :activeNavItem="activeNavItem"
        :haveButtonsSector="haveButtonsSector"
        @changeActiveNavItem="(navItem) => $emit('changeActiveNavItem', navItem)"
    />
    <ListingsFieldsLabels
        v-if="!mobileView && customerOffers[activeNavItem].length !== 0"
        :mobileView="mobileView"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SideNavBar from "@/components/Offers/SideNavBar";
import ListingsFieldsLabels from "@/components/Offers/ListingsFieldsLabels";
export default {
  name: "OffersSidebar",
  props: ["mobileView", "activeView", "activeNavItem", "haveButtonsSector"],
  components: {
    SideNavBar,
    ListingsFieldsLabels
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    ...mapGetters(["customerOffers"]),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-sidebar {
  min-height: 468px;
  min-width: 310px;
  margin-top: -68px;
}

.offers-sidebar-desktop {
  width: 310px;
}

</style>
