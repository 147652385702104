var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "offers-header",
    style: _vm.mobileView ? 'padding-bottom: 0' : 'padding-bottom: 42px'
  }, [_c('div', {
    staticClass: "offers-header-first-row"
  }, [_c('div', {
    staticClass: "offers-header-first-col",
    style: _vm.mobileView ? 'display: grid' : 'display: flex'
  }, [_c('div', {
    staticClass: "offers-header-logo",
    style: _vm.mobileView ? 'padding-left: 8px' : 'padding-left: 0px'
  }, [_vm.company ? _c('img', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "src": _vm.company.name,
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../assets/images/rpFullLogo.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "offers-header-text"
  }, [_vm.customer ? _c('h4', [_vm._v(" " + _vm._s(_vm.$t("Hello")) + " " + _vm._s(_vm.customer.customerName) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.$t("OffersInfo")) + " ")])])]), _c('div', [_c('LanguageDropdown')], 1)]), _vm.mobileView ? _c('TopNavBar', {
    attrs: {
      "mobileView": _vm.mobileView,
      "activeView": _vm.activeView
    },
    on: {
      "changeActiveView": function (view) {
        return _vm.$emit('changeActiveView', view);
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "separator"
  }), !_vm.mobileView ? _c('TopNavBar', {
    attrs: {
      "mobileView": _vm.mobileView,
      "activeView": _vm.activeView
    },
    on: {
      "changeActiveView": function (view) {
        return _vm.$emit('changeActiveView', view);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }