<template>
  <div
      class="offers-side-nav-bar"
      :style="haveButtonsSector ? null : 'min-height: 324px'"
  >
    <div class="offers-side-nav-bar-buttons">
      <div
        :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'offers'
        }"
        @click="$emit('changeActiveNavItem', 'offers')"
      >
        <div>
          {{ $t("Offers") }}
        </div>
        <div>
          ({{ customerOffers.offers.length }})
        </div>
      </div>

      <div
          :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'interested'
        }"
          @click="$emit('changeActiveNavItem', 'interested')"
      >
        <div>
          {{ $t("Selected") }}
        </div>
        <div>
          ({{ customerOffers.interested.length }})
        </div>
      </div>

      <div
          :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'lost'
        }"
          @click="$emit('changeActiveNavItem', 'lost')"
      >
        <div>
          {{ $t("Rejected") }}
        </div>
        <div>
          ({{ customerOffers.lost.length }})
        </div>
      </div>

      <div
          :class="{
          'transparent-button': 1,
          'active-button': activeNavItem === 'viewed'
        }"
          @click="$emit('changeActiveNavItem', 'viewed')"
      >
        <div>
          {{ $t("Viewed") }}
        </div>
        <div>
          ({{ customerOffers.viewed.length }})
        </div>
      </div>

    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: "OffersSideNavBar",
  components: {
  },
  props: ["activeView", "mobileView", "activeNavItem", "haveButtonsSector"],
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {},
  computed: {
    ...mapGetters(["customerOffers"]),
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-side-nav-bar {
  min-height: 403px;
  padding: 48px;
}

.transparent-button {
  min-width: 160px;
}

.active-button {
  background: black !important;
  color: white;
}

.offers-side-nav-bar-buttons {
  gap: 8px;
  display: inline-grid;
}
</style>
