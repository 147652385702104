<template>
  <div>
    <div
      :class="{
        'offers-top-nav-bar': 1,
        'offers-top-nav-bar-desktop': !mobileView,
        'offers-top-nav-bar-mobile': mobileView,
      }"
    >
      <div
          :class="{
            'offers-top-nav-bar-item': 1,
            'active-view': activeView === 'list',
            'active-view-mobile': activeView === 'list' && mobileView
          }"
          @click="$emit('changeActiveView', 'list')"
          style="display: none"
      >
        <img
          src="../../assets/images/bulletedList.svg"
          alt=""
          class="img20"
        />
        <span>
          {{ $t("List") }}
        </span>
      </div>

      <div
          :class="{
            'offers-top-nav-bar-item': 1,
            'active-view': activeView === 'map',
            'active-view-mobile': activeView === 'map' && mobileView
          }"
          @click="$emit('changeActiveView', 'map')"
          style="display: none"
      >
        <img
            src="../../assets/images/map.svg"
            alt=""
            class="img20"
        />
        <span>
          {{ $t("MapView") }}
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "OffersTopNavBar",
  props: ["mobileView", "activeView"],
  components: {
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.offers-sub-header {

}

.offers-top-nav-bar {
  display: flex;
  gap: 40px;
  align-items: center;
  min-height: 68px;
}

.offers-top-nav-bar-desktop {
  padding-left: 310px;
  padding-top: 24px;
}

.offers-top-nav-bar-mobile {
  padding-left: 16px;
}

.offers-top-nav-bar-item {
  display: inline-flex;
  gap: 14px;
  padding-bottom: 24px;
  cursor: pointer;
}

.active-view {
  font-weight: 700;
}

.active-view-mobile {
  border-bottom: 2px solid black;
}
</style>
